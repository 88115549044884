import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../stores/app.store.config';
import { useForm } from 'react-hook-form';
import { usePrismic } from '../../../hooks/prismic.hook';
import { usePlan } from '../../../stores/selectors/plan.selector';
import { useAuth } from '../../../stores/selectors/auth.selector';
import { ghostSignUp } from '../../../stores/actions/auth.action';
import { updateEmail } from '../../../stores/slices/auth.slice';
import Popup from 'reactjs-popup';
import { usePurchase } from '../../../stores/selectors/purchase.selector';
import { makePurchase } from '../../../stores/actions/purchase.actions';
import { useCpmStar } from '../../../stores/selectors/cpmstar.selector';
import { onKeyDown } from '../../../services/utils/forms.utils';

const BuyPageAboutYou = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { result } = usePrismic('buy_page');

  const { about_you_title, about_you_description, already_signed_in, email_tooltip, email_tooltip_description } =
    result;

  const { contents } = usePlan();
  const { register } = useForm();

  const { user, error } = useAuth();

  const { loading } = usePurchase();

  const [buttonActive, setButtonActive] = useState(false);

  const { pricingId = '', campaignId = '', productId = '' } = contents || {};

  const {
    result: { continue: continueLabel, email_placeholder, required },
  } = usePrismic('common');

  useEffect(() => {
    if (user) {
      dispatch(updateEmail(user.email));
    }
  }, []);
  const { content: cpmContent } = useCpmStar();
  const auth: any = useAuth();

  const buttonAction = async (): Promise<void> => {
    setButtonActive(true);

    if (!auth.user && Object.keys(cpmContent).length > 0) {
      dispatch(
        ghostSignUp({
          email: auth.email,
          siteid: cpmContent.siteid,
          ref: cpmContent.ref,
        }),
      );
    } else if (!auth.user) {
      dispatch(
        ghostSignUp({
          email: auth.email,
        }),
      );
    }

    if (user) {
      dispatch(makePurchase({ pricingId, campaignId, productId }));
    }
  };

  useEffect(() => {
    if (user && buttonActive) {
      buttonAction();
      setButtonActive(false);
    }
  }, [user]);

  const displayError = (): JSX.Element => {
    if (!error) return <></>;
    const messages = [];
    if (error.errors) {
      for (const key of Object.keys(error.errors)) {
        messages.push(error.errors[key].map((e: string): string => result[`error_${e.toLowerCase()}`]));
      }
    }
    if (error.code === 2) messages.push(about_you_title);
    return <div className={'errorMessage'}>{messages.join(', ')}</div>;
  };

  return user ? (
    <section className="buypage-aboutyou">
      <div className="container">
        <h5>{about_you_title}</h5>
        <div className="util-input">
          <div />
          <input
            type={'text'}
            className="util-input-text"
            placeholder={email_placeholder}
            value={user.email}
            disabled
          />
          <div />
        </div>
        <p className="desc">{about_you_description}</p>
        {contents && (
          <div className={'centerButton'}>
            <button
              disabled={loading}
              className={`theme-button-spec-positive continue`}
              data-xpaystation-widget-open
              onClick={buttonAction}
            >
              <div className="theme-button-container">
                <div />
                <span>{continueLabel}</span>
              </div>
            </button>
          </div>
        )}
      </div>
    </section>
  ) : (
    <>
      <section className="buypage-aboutyou">
        <div className="container">
          <h5>{about_you_title}</h5>

          {displayError()}
          <div className="tooltip-container">
            <p className="input-title">{email_tooltip}</p>
            <Popup
              trigger={(): JSX.Element => (
                <div className="tooltip-box">
                  <span className="buttoncustom">i</span>
                </div>
              )}
              position="top center"
              on="hover"
              closeOnDocumentClick
            >
              <span>{email_tooltip_description}</span>
            </Popup>
          </div>
          <div className="util-input">
            <div />
            <input
              name="email"
              type="email"
              className="util-input-text"
              placeholder={email_placeholder}
              ref={register({
                required,
              })}
              onChange={(event): any => {
                dispatch(updateEmail(event.target.value));
              }}
              onKeyDown={(e: any): void => onKeyDown(e, () => buttonAction())}
            />

            <div />
          </div>
          <p className="desc">{about_you_description}</p>
          {contents && (
            <div className={'centerButton'}>
              <button
                disabled={loading}
                className={`theme-button-spec-positive continue`}
                data-xpaystation-widget-open
                onClick={buttonAction}
              >
                <div className="theme-button-container">
                  <div />
                  <span>{continueLabel}</span>
                </div>
              </button>
            </div>
          )}

          <p className="signin">{already_signed_in}</p>
        </div>
      </section>
    </>
  );
};

export default BuyPageAboutYou;
