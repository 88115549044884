import React, { useState } from 'react';
import axios from 'axios';
import { displayDate } from '../../../services/utils/utils.service';
import { usePrismic } from '../../../hooks/prismic.hook';
import { getEnglishRouteByID, getUrlFromEnglish } from '../../../services/i18n/i18n.url.service';
import { goto } from '../../../stores/selectors/router.selector';
import { SnackBarComponent } from '../../../features/snackBar/snackBar.component';
import LoadingComponent from '../../../features/loading/loading.component';
import PrismicRichText, { asText } from '../../../services/prismic/prismic.richtext.service';

const ConvertModal = ({ onClick, user }: any): JSX.Element => {
  const { contents } = usePrismic('errors');
  const {
    data: {
      title,
      description,
      box_title_continue,
      box_title_cancel,
      box_expiring,
      box_days_left,
      button_continue_label,
      button_cancel_label,
      disclamer,
      forbidden,
    },
  } = usePrismic('buy_page_convert_modal');

  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  if (!contents) return <></>;

  let gametime = '';
  user?.allPlans[0]?.products?.map((product: any) => {
    if (product?.product?.name == 'Subscriber') gametime = product?.product?.gameTime;
  });

  const todayDate = new Date();

  const currentGametime = new Date(gametime);

  const ninetyDaysLess = new Date(gametime);

  ninetyDaysLess.setDate(currentGametime.getDate() - 90);

  const differenceInMilliseconds = currentGametime.valueOf() - todayDate.valueOf();
  const differenceInMilliseconds2 = ninetyDaysLess.valueOf() - todayDate.valueOf();

  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  const differenceInDays = Math.floor(differenceInMilliseconds / millisecondsPerDay);
  const differenceInDays2 = Math.floor(differenceInMilliseconds2 / millisecondsPerDay);

  const redeemMyDU = async () => {
    try {
      setLoading(true);
      await axios.post(
        '/api/users/me/redeem-myDU',
        {},
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        },
      );
      const url = getUrlFromEnglish(getEnglishRouteByID('buy-thankyou'));
      goto(`${url}${window.location.search}?product=myDU`);
    } catch (err: any) {
      setErrorMessage('An error occurred:' + err?.Message);
      console.log(err);
    }
  };

  return (
    <div className="convert-modal-container">
      {ninetyDaysLess > todayDate && differenceInDays2 !== 0 && (
        <div className="corners">
          {errorMessage && <SnackBarComponent message={errorMessage} type="failure" />}
          <div className="convert-modal-title">
            <PrismicRichText render={title} />
          </div>
          <div className="convert-modal-desc">
            <PrismicRichText render={description} />
          </div>
          <div className="convert-columns">
            <div className="column column 1">
              <div className="column-1-title">
                <PrismicRichText render={box_title_continue} />
              </div>
              <div className="column-1-logo" />
              <div className="column-1-date-exp">
                {asText(box_expiring)}
                {displayDate(gametime)}
              </div>
              <div className="column-days">
                {differenceInDays} {asText(box_days_left)}
              </div>
            </div>
            <div className="convert-arrow" />
            <div className="column column-2">
              <div className="column-1-title">
                <PrismicRichText render={box_title_cancel} />
              </div>
              <div className="column-1-logo" />
              <div className="column-1-date-exp">
                {asText(box_expiring)} {displayDate(ninetyDaysLess.toDateString())}
              </div>
              <div className="column-days column-days-2">
                {differenceInDays2} {asText(box_days_left)}
              </div>
            </div>
          </div>
          <div className="convert-buttons">
            <button className={`theme-button-spec-positive continue`} onClick={redeemMyDU}>
              <div className="theme-button-container">
                <div />
                <span>{asText(button_continue_label)}</span>
              </div>
            </button>
            <button className={`theme-button-spec-positive cancel`} onClick={onClick}>
              <div className="theme-button-container">
                <div />
                <span>{asText(button_cancel_label)}</span>
              </div>
            </button>
          </div>
          <LoadingComponent loading={loading} />
          <div className="convert-disclamer">
            <PrismicRichText render={disclamer} />
          </div>
        </div>
      )}
      {(ninetyDaysLess < todayDate || !gametime || differenceInDays2 === 0) && (
        <div className="corners">
          <div className="convert-modal-title">
            <PrismicRichText render={forbidden} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ConvertModal;
