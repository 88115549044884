import React, { useState, useEffect } from 'react';
import { useAppDispatch } from '../../../stores/app.store.config';
import { setPlan } from '../../../stores/slices/plan.slice';
import { useAuth } from '../../../stores/selectors/auth.selector';
import { usePlansAvailable } from '../../../stores/selectors/plans-available.selector';
import { displayCurrency } from '../../../services/utils/currency.utils';
import config from '../../../../config';
import RichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import { Action, triggerActionGTM, category } from '../../../services/optimization/ecommerce.service';
import LinkComponent from '../../link/link.component';
import { usePrismic } from '../../../hooks/prismic.hook';
// import ScrollAnimation from 'react-animate-on-scroll';
import { getBuyPageStatus } from '../../../services/api/orders/order.subscriptions.service';
import BuyPageUnavailableModal from './buypage-notice';
import PrismicRichText from '../../../services/prismic/prismic.richtext.service';

const BuyPagePackages = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const email = '';
  const {
    contents: { campaignId, productApi: productsApiDataUnsorted = [] },
  } = usePlansAvailable();
  const [borderOne, setBorderOne] = useState(false);
  const [borderTwo, setBorderTwo] = useState(false);
  const [borderThree, setBorderThree] = useState(false);
  const [borderFour, setBorderFour] = useState(false);

  const [product, setProduct] = useState(Object);
  const [currencyCode, SetCurrencyCode] = useState('');

  const { isAuthenticated, user }: { isAuthenticated: boolean; user: any } = useAuth();

  const [buyPageEnabled, setBuyPageEnabled] = useState(false);
  const [openReminder, setOpenReminder] = useState(false);
  const existingProductId = false;

  const {
    result: {
      continue_button,
      existing_plan_disclaimer,
      limited_time_offer,
      // limited_offer_label,
      month,
      save,
      promotion,
      recommended,
      mydu_button,
      // steam_btn_left_label,
      // steam_btn_right_label,
      // steam_btn_left_link,
      // steam_btn_right_link,
    },
    data: { page_title, page_title_promotion, page_logo, mydu_enabled, mydu_title, mydu_description, mydu_disclamer },
    contents: contentsRaw,
  } = usePrismic('buy_page');

  const {
    results: [
      {
        data: { disclaimer_yellow },
      },
    ],
  } = contentsRaw;
  const lastProduct = productsApiDataUnsorted[4];
  const productsApiData = productsApiDataUnsorted
    .slice(0, 4)
    .sort((a: Record<string, any>, b: Record<string, any>): number => a.price - b.price);

  useEffect(() => {
    if (productsApiData.length) {
      const currencyCode = productsApiData[0].currency;
      const products = productsApiData.map((p: any, index: number) => ({
        name: p.planname,
        id: p.productId,
        price: p.price,
        category,
        position: index + 1,
      }));
      triggerActionGTM(Action.ProductImpression, { currencyCode, products });
    }
  }, [productsApiData]);

  useEffect(() => {
    retrieveBuyPageStatus();
  }, []);

  const retrieveBuyPageStatus = async () => {
    try {
      const data = await getBuyPageStatus();
      setBuyPageEnabled(data);
      if (!data) setOpenReminder(true);
    } catch (error: any) {
      console.log(error);
    }
  };

  if (!productsApiData.length && config.nodeEnv !== 'test') return <div></div>;

  const planIds = productsApiData.map(({ productId }: { productId: string }) => productId);
  const pricingId = productsApiData.map(({ pricingId }: { pricingId: string }) => pricingId);
  const planNames = productsApiData.map(({ planname }: { planname: string }) => planname);
  const planDescriptions = productsApiData.map(({ plandescription }: { plandescription: string }) => plandescription);
  const planPrices = productsApiData.map(({ price }: { price: number }) => price);
  const planCurrency = productsApiData.map(({ currency }: { currency: string }) => currency);
  // const planFreeTimes = productsApiData.map(({ plansubscriptionfreemonths }: { plansubscriptionfreemonths: string }) =>
  //   `${plansubscriptionfreemonths}`.toUpperCase(),
  // );

  const paymentProviderId = productsApiData.map(
    ({ paymentProviderId }: { paymentProviderId: string }) => paymentProviderId,
  );

  const type = productsApiData.map(({ type }: { type: number }) => type);

  const [planIdOne, planIdTwo, planIdThree, planIdFour] = planIds;
  const [planNameOne, planNameTwo, planNameThree, planNameFour] = planNames;
  const [planDescOne, planDescTwo, planDescThree, planDescFour] = planDescriptions;

  let [priceOne, priceTwo, priceThree, priceFour] = planPrices;

  // const priceOneOld = priceOne;
  // const priceTwoOld = priceTwo;
  // const priceThreeOld = priceThree;
  // const priceFourOld = priceFour;
  priceOne = priceOne - priceOne * promotion;
  priceTwo = priceTwo - priceTwo * promotion;
  priceThree = priceThree - priceThree * promotion;
  priceFour = priceFour - priceFour * promotion;
  // const [freeMonthOne, freeMonthTwo, freeMonthThree, freeMonthFour] = planFreeTimes;

  const nbMonthsOne = Number(planNameOne?.split(' ')[0]);

  // const emptyFunction = (): void => {
  //   if (config.local) {
  //     console.log('empty function');
  //   }
  // };

  return (
    <div className={'buypageparent'}>
      {openReminder && <BuyPageUnavailableModal setOpenReminder={setOpenReminder} />}
      {isAuthenticated && existingProductId && <h3 style={{ color: 'orange' }}>{existing_plan_disclaimer}</h3>}
      {
        <div className="notice-notlogin">
          <p>{limited_time_offer}</p>
        </div>
      }
      <div className={'buypage-packages'}>
        <div className="buypage-packages-planet1 image" />
        <div className="buypage-packages-planet2 image" />
        <div className="buy-packages-logo image" style={{ backgroundImage: `url(${page_logo?.url})` }}></div>
        <div className="title-wrapper">
          {!Boolean(Number(promotion)) && (
            <h2 data-testid="buy-title">
              {asText(page_title)}&nbsp;{displayCurrency(priceOne / nbMonthsOne, planCurrency[0])}/ {month}!
            </h2>
          )}
          {Boolean(Number(promotion)) && (
            <h2 data-testid="buy-title" className="promotion-title">
              <PrismicRichText render={page_title_promotion} />
            </h2>
          )}
        </div>
        <div className={'image-container'}>
          <div className={!borderOne ? 'priceBox-container' : 'priceBox-container selected'}>
            <div
              className={borderOne ? 'priceBox borderToggle' : 'priceBox'}
              onClick={(): void => {
                setBorderOne(!borderOne);
                setBorderTwo(false);
                setBorderThree(false);
                setBorderFour(false);

                const currencyCode = productsApiData[0].currency;
                const product = {
                  name: planNameOne,
                  id: planIdOne,
                  price: priceOne,
                  position: 1,
                  category,
                };
                setProduct(product);
                SetCurrencyCode(currencyCode);

                dispatch(
                  setPlan({
                    planName: planNameOne,
                    planDescription: planDescOne,
                    campaignId,
                    productId: planIdOne,
                    pricingId: pricingId[0],
                    paymentProviderId: paymentProviderId[0],
                    price: priceOne,
                    type: type[0],
                    email,
                    currency: planCurrency[0],
                  }),
                );
              }}
            >
              <div className="real-content">
                <div className="buyPage-logo-time">
                  <div className={'buyPage-logo buyPage-logo-1'} />
                  <div className={'buyPage-time'}>{planNameOne}</div>
                </div>
                <div className="price-box">
                  {/* {Boolean(Number(promotion)) && (
                    <s className="buyPage-price-before">
                      {displayCurrency(priceOneOld, planCurrency[0])}/{month}
                    </s>
                  )} */}
                  <p className={'buyPage-price-month'} style={{ marginBottom: 0 }}>
                    {displayCurrency(priceOne, planCurrency[0])}/{month}
                  </p>
                  {/* <p className={'buyPage-price hide'}>{displayCurrency(priceOne, planCurrency[0])}</p> */}
                </div>
                {/* <p className={'buyPage-free'}>{freeMonthOne}</p> */}
              </div>
            </div>
          </div>
          <div className={!borderTwo ? 'priceBox-container' : 'priceBox-container selected'}>
            <div
              className={
                borderTwo
                  ? 'priceBox priceBox-mobile borderToggle important delay-one'
                  : 'priceBox priceBox-mobile important delay-one'
              }
              onClick={(): void => {
                setBorderTwo(!borderTwo);
                setBorderOne(false);
                setBorderThree(false);
                setBorderFour(false);

                const currencyCode = productsApiData[0].currency;
                const product = {
                  name: planNameTwo,
                  id: planIdTwo,
                  price: priceTwo,
                  position: 2,
                  category,
                };
                setProduct(product);
                SetCurrencyCode(currencyCode);

                dispatch(
                  setPlan({
                    planName: planNameTwo,
                    planDescription: planDescTwo,
                    campaignId,
                    productId: planIdTwo,
                    pricingId: pricingId[1],
                    paymentProviderId: paymentProviderId[1],
                    price: priceTwo,
                    type: type[1],
                    email,
                    currency: planCurrency[1],
                  }),
                );
              }}
            >
              <div className="real-content">
                <div className="buyPage-logo-time">
                  <div className={'buyPage-logo buyPage-logo-2'} />
                  <div>
                    <div className="buyPage-time-recommended">{recommended}</div>
                    <div className={'buyPage-time buyPage-time-mobile'}>{planNameTwo}</div>
                  </div>
                </div>
                <div className="price-box">
                  {/* {Boolean(Number(promotion)) && (
                    <s className={'buyPage-price-before'}>
                      {displayCurrency(priceTwoOld / 3, planCurrency[1])}/{month}
                    </s>
                  )} */}
                  <div className={'buyPage-free'}>
                    {save} {displayCurrency(priceOne * 3 - priceTwo, planCurrency[1])}
                  </div>
                  <p className={'buyPage-price-month'}>
                    {displayCurrency(priceTwo / 3, planCurrency[1])}/{month}
                  </p>
                  <div className={'buyPage-price'}>Total {displayCurrency(priceTwo, planCurrency[1])}</div>
                  {/* <div className={'buyPage-free mobile'}>{freeMonthTwo}</div> */}
                </div>
                {/* {freeMonthTwo} */}
              </div>
            </div>
          </div>
          <div className={!borderThree ? 'priceBox-container' : 'priceBox-container selected'}>
            <div
              className={
                borderThree ? 'priceBox priceBox-mobile borderToggle delay-two' : 'priceBox priceBox-mobile delay-two'
              }
              onClick={(): void => {
                setBorderThree(!borderThree);
                setBorderOne(false);
                setBorderTwo(false);
                setBorderFour(false);

                const currencyCode = productsApiData[0].currency;
                const product = {
                  name: planNameThree,
                  id: planIdThree,
                  price: priceThree,
                  position: 3,
                  category,
                };
                setProduct(product);
                SetCurrencyCode(currencyCode);

                dispatch(
                  setPlan({
                    planName: planNameThree,
                    planDescription: planDescThree,
                    campaignId,
                    productId: planIdThree,
                    pricingId: pricingId[2],
                    paymentProviderId: paymentProviderId[2],
                    price: priceThree,
                    type: type[2],
                    email,
                    currency: planCurrency[2],
                  }),
                );
              }}
            >
              <div className="real-content">
                <div className="buyPage-logo-time">
                  <div className={'buyPage-logo buyPage-logo-3'} />
                  <div className={'buyPage-time'}>{planNameThree}</div>
                </div>
                <div className="price-box">
                  {/* {Boolean(Number(promotion)) && (
                      <s className={'buyPage-price-before'}>
                        {displayCurrency(priceThreeOld / 6, planCurrency[2])}/{month}
                      </s>
                    )} */}
                  <div className={'buyPage-free'}>
                    {save} {displayCurrency(priceOne * 6 - priceThree, planCurrency[2])}
                  </div>
                  <p className={'buyPage-price-month'}>
                    {displayCurrency(priceThree / 6, planCurrency[2])}/{month}
                  </p>
                  <div className={'buyPage-price'}>Total {displayCurrency(priceThree, planCurrency[2])}</div>
                  {/* <div className={'buyPage-free mobile'}>{freeMonthThree}</div> */}
                </div>
                {/* {freeMonthThree} */}
              </div>
            </div>
          </div>
          <div className={!borderFour ? 'priceBox-container' : 'priceBox-container selected'}>
            <div
              className={borderThree ? 'priceBoxBest borderToggle delay-three' : 'priceBoxBest delay-three'}
              onClick={(): void => {
                setBorderFour(!borderFour);
                setBorderOne(false);
                setBorderTwo(false);
                setBorderThree(false);

                const currencyCode = productsApiData[0].currency;
                const product = {
                  name: planNameFour,
                  id: planIdFour,
                  price: priceFour,
                  position: 4,
                  category,
                };
                setProduct(product);
                SetCurrencyCode(currencyCode);

                dispatch(
                  setPlan({
                    planName: planNameFour,
                    planDescription: planDescFour,
                    campaignId,
                    productId: planIdFour,
                    pricingId: pricingId[3],
                    paymentProviderId: paymentProviderId[3],
                    price: priceFour,
                    type: type[3],
                    email,
                    currency: planCurrency[3],
                  }),
                );
              }}
            >
              <div className="real-content">
                <div className="buyPage-logo-time">
                  <div className={'buyPage-logo buyPage-logo-4'} />
                  <div className={'buyPage-time'}>{planNameFour}</div>
                </div>
                <div className="price-box">
                  {/* {Boolean(Number(promotion)) && (
                      <s className={'buyPage-price-before'}>
                        {displayCurrency(priceFourOld / 12, planCurrency[3])}/{month}
                      </s>
                    )} */}
                  <div className={'buyPage-free'}>
                    {save} {displayCurrency(priceOne * 12 - priceFour, planCurrency[3])}
                  </div>
                  <p className={'buyPage-price-month'}>
                    {displayCurrency(priceFour / 12, planCurrency[3])}/{month}
                  </p>
                  <div className={'buyPage-price'}>Total {displayCurrency(priceFour, planCurrency[3])}</div>
                  {/* <div className={'buyPage-free mobile'}>{freeMonthFour}</div> */}
                </div>
                {/* {freeMonthFour} */}
              </div>
            </div>
          </div>
        </div>
        <LinkComponent className="btn-wrapper" link="/buy/confirm">
          <button
            disabled={!buyPageEnabled}
            className="theme-button"
            onClick={() => {
              triggerActionGTM(Action.ProductClick, { product, currencyCode });
              triggerActionGTM(Action.AddToCart, { product, currencyCode });
            }}
          >
            <div className="theme-button-container">
              <div />
              <span>{asText(continue_button)}</span>
            </div>
          </button>
        </LinkComponent>
        <div className={'buyPage-disclaimer'}>
          <RichText render={disclaimer_yellow} />
        </div>
      </div>
      {mydu_enabled && (
        <div className="buypage-mydu">
          <div className="image mydu-image">
            <button className={'limited-offer'}>
              <span>New!</span>
            </button>
          </div>
          <div className="image mydu-logo" />
          <div className="mydu-title">
            <PrismicRichText render={mydu_title} />
          </div>
          <div className="mydu-description">
            <PrismicRichText render={mydu_description} />
          </div>
          <div className="mydu-price">{displayCurrency(lastProduct?.price, lastProduct?.currency)}</div>

          <LinkComponent className="btn-wrapper btn-wrapper-myDu" link="/buy/confirm">
            <button
              disabled={!buyPageEnabled || user?.details?.hasMyDUAccess}
              className="theme-button theme-button-mydu"
              onClick={() => {
                const currencyCode = productsApiData[0].currency;
                const product = {
                  name: lastProduct?.planname,
                  id: lastProduct?.productId,
                  price: lastProduct?.price,
                  position: 5,
                  category,
                };
                setProduct(product);
                SetCurrencyCode(currencyCode);
                dispatch(
                  setPlan({
                    planName: lastProduct?.planname,
                    planDescription: lastProduct?.plandescription,
                    campaignId,
                    productId: lastProduct?.productId,
                    pricingId: lastProduct?.pricingId,
                    price: lastProduct?.price,
                    type: lastProduct?.type,
                    email,
                    currency: lastProduct?.currency,
                  }),
                );
              }}
            >
              <div className="theme-button-container">
                <div />
                <span>{mydu_button}</span>
              </div>
            </button>
          </LinkComponent>
          {user?.details?.hasMyDUAccess && (
            <div className="mydu-message">
              <PrismicRichText render={mydu_disclamer} />
            </div>
          )}
          {/* <div className="mydu-disclamer">
            <PrismicRichText render={mydu_disclamer} />
          </div> */}
        </div>
      )}
    </div>
  );
};

export default BuyPagePackages;
