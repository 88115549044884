import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../stores/app.store.config';
import { useForm } from 'react-hook-form';
import { usePrismic } from '../../../hooks/prismic.hook';
import { useAuth } from '../../../stores/selectors/auth.selector';
import { updateEmail } from '../../../stores/slices/auth.slice';
import Popup from 'reactjs-popup';
import { usePurchase } from '../../../stores/selectors/purchase.selector';
import { makePurchase } from '../../../stores/actions/purchase.actions';
import { usePlan } from '../../../stores/selectors/plan.selector';
import { ghostSignUp } from '../../../stores/actions/auth.action';
import { onKeyDown } from '../../../services/utils/forms.utils';
import PrismicRichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import { initiateSteamPayment } from '../../../services/api/orders/order.subscriptions.service';
import { useI18n } from '../../../stores/selectors/i18n.selector';
import { getPrismicError } from '../../../services/utils/utils.service';
import ConvertModal from './buy-page-convert-modal';

const BuyPageAboutYou = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { result } = usePrismic('buy_page');
  const { contents: contentErrors } = usePrismic('errors');
  const {
    about_you_title,
    about_you_description,
    already_signed_in,
    email_tooltip,
    email_tooltip_description,
    signin_box_before,
    signin_box_after,
  } = result;
  const {
    data: { about_you_checkbox1, about_you_checkbox2, about_you_checkbox3, convert_gt_title, convert_gt_desc },
  } = usePrismic('buy_page');
  const { contents } = usePlan();
  const { register } = useForm();

  const { user, error, email } = useAuth();

  const { loading } = usePurchase();
  const { locale } = useI18n();
  const [convertModal, setConvertModal] = useState(false);

  const [checkBox1State, setCheckBox1State] = useState(false);
  const [checkBox2State, setCheckBox2State] = useState(false);
  const [checkBox3State, setCheckBox3State] = useState(false);

  const { pricingId = '', campaignId = '', productId = '', planDescription = '' } = contents || {};

  const [userFull, setUserFull] = useState(Object);
  const [errorSteam, setErrorSteam] = useState(Object);
  const {
    result: { continue: continueLabel, email_placeholder, required },
  } = usePrismic('common');

  useEffect(() => {
    if (user) {
      dispatch(updateEmail(user.email));
      setUserFull(user?.details);
    }
  }, [user]);

  const buttonAction = (): void => {
    // setButtonActive(true);
    if (!user) {
      dispatch(
        ghostSignUp({
          email,
        }),
      );
    }
    if (user) {
      dispatch(makePurchase({ pricingId, campaignId, productId }));
    }
  };

  // useEffect(() => {
  //   if (user && buttonActive && !userFull?.ownership?.includes('steam')) {
  //     buttonAction();
  //     setButtonActive(false);
  //   }
  // }, [user]);

  const displayErrorType1 = (errors: any): JSX.Element => {
    const messages = [];
    if (errors) {
      for (const key of Object.keys(errors)) {
        messages.push(errors[key]);
      }
    }
    const result = messages.join(', ').replaceAll('_', ' ').toLowerCase();
    return <div className={'errorMessage'}>{result[0].toUpperCase() + result.slice(1).toLowerCase()}</div>;
  };
  const displayErrorType2 = (error: any): JSX.Element => {
    return <div className={'errorMessage'}>{getPrismicError(errorsPrismic, error)}</div>;
  };

  const contentStyle = { background: '#ffd255' };
  const overlayStyle = { background: 'transparent' };
  const arrowStyle = { color: '#ffd255' };

  const initiatePayment = async () => {
    try {
      const data = await initiateSteamPayment(productId, planDescription, locale?.slice(0, 2));
      window.location.href = data?.steamUrl + `?returnurl=https://${window.location.host}/buy/thankyou?source=steam`;
    } catch (error: any) {
      setErrorSteam(error?.response?.data);
    }
  };
  if (!contentErrors) return <></>;
  const {
    results: [
      {
        data: { errors: errorsPrismic },
      },
    ],
  } = contentErrors;
  const closeModal = (): void => {
    setConvertModal(false);
  };

  return user ? (
    <>
      <Popup
        lockScroll
        modal
        className="convert-popup-container"
        closeOnDocumentClick={true}
        open={convertModal}
        onClose={closeModal}
      >
        <div className="popup-container-convert">
          <div className="exit-icon" onClick={closeModal} />
          <ConvertModal onClick={closeModal} user={user} />
        </div>
      </Popup>
      <section className="buypage-aboutyou">
        <div className="container">
          <div className="title">
            <p>{asText(about_you_title)}</p>
          </div>
          {errorSteam?.errors && displayErrorType1(errorSteam?.errors)}
          {errorSteam?.code && displayErrorType2(errorSteam)}
          {/* <div className="util-input">
            <div />
            <input
              type={'text'}
              className="util-input-text"
              placeholder={email_placeholder}
              value={user.email}
              disabled
            />
            <div />
          </div> */}
          {/* <p className="desc">{about_you_description}</p> */}
          <div className="checkboxcontainer checkbox">
            <input
              type="checkbox"
              onClick={() => {
                setCheckBox1State(!checkBox1State);
              }}
            />
            <div className="checkbox-label">
            <PrismicRichText render={planDescription?.includes('MyDU') ? about_you_checkbox3 : about_you_checkbox1} />
            </div>
          </div>
          {(!userFull?.ownership?.includes('steam') || planDescription?.includes('MyDU')) && (
            <div className="checkboxcontainer checkbox second">
              <input
                type="checkbox"
                onClick={() => {
                  setCheckBox2State(!checkBox2State);
                }}
              />
              <div className="checkbox-label">
                <PrismicRichText render={about_you_checkbox2} />
              </div>
            </div>
          )}
          {contents && (
            <div className={'centerButton'}>
              {userFull?.ownership?.includes('steam') && !planDescription?.includes('MyDU') && (
                <button
                  disabled={
                    loading || !checkBox1State || !userFull?.ownership?.includes('steam') ? !checkBox2State : false
                  }
                  className={`theme-button-spec-positive continue`}
                  onClick={() => {
                    initiatePayment();
                  }}
                >
                  <div className="theme-button-container">
                    <div />
                    <span>{continueLabel}</span>
                  </div>
                </button>
              )}
              {(!userFull?.ownership?.includes('steam') || planDescription?.includes('MyDU')) && (
                <button
                  disabled={loading || !checkBox1State || !checkBox2State}
                  className={`theme-button-spec-positive continue`}
                  data-xpaystation-widget-open
                  onClick={buttonAction}
                >
                  <div className="theme-button-container">
                    <div />
                    <span>{continueLabel}</span>
                  </div>
                </button>
              )}
            </div>
          )}
          <div className="signin-box">
            <p>
              {asText(signin_box_before)} <span>{user.email}</span> , {asText(signin_box_after)}.
            </p>
          </div>
        </div>
      </section>
      {planDescription?.includes('MyDU') && (
        <section className="buypage-aboutyou convert-gt">
          <div className="container">
            <div className="title">
              <PrismicRichText render={convert_gt_title} />
            </div>
            <div className="convert-desc">
              <PrismicRichText render={convert_gt_desc} />
            </div>
            <div className="checkboxcontainer checkbox">
              <input
                type="checkbox"
                onClick={() => {
                  setCheckBox3State(!checkBox3State);
                }}
              />
              <div className="checkbox-label">
                <PrismicRichText render={about_you_checkbox3} />
              </div>
            </div>
            <div className={'centerButton'}>
              <button
                disabled={!checkBox3State}
                className={`theme-button-spec-positive continue`}
                onClick={() => setConvertModal(true)}
              >
                <div className="theme-button-container">
                  <div />
                  <span>{continueLabel}</span>
                </div>
              </button>
            </div>
            <div className="signin-box">
              <p>
                {asText(signin_box_before)} <span>{user.email}</span> , {asText(signin_box_after)}.
              </p>
            </div>
          </div>
        </section>
      )}
    </>
  ) : (
    <>
      <section className="buypage-aboutyou">
        <div className="container">
          <div className="title">
            <p>{asText(about_you_title)}</p>{' '}
          </div>
          {error?.errors && displayErrorType1(error?.errors)}
          <div className="tooltip-container">
            <p className="input-title">{email_tooltip}</p>
            <Popup
              trigger={(): JSX.Element => (
                <div className="tooltip-box">
                  <span className="buttoncustom">i</span>
                </div>
              )}
              position="top center"
              on="hover"
              {...{ contentStyle, overlayStyle, arrowStyle }}
              closeOnDocumentClick
            >
              <div className="popup-content-tooltip">
                <span>{email_tooltip_description}</span>
              </div>
            </Popup>
          </div>
          <div className="util-input">
            <div />
            <input
              name="email"
              type="email"
              className="util-input-text"
              placeholder={email_placeholder}
              ref={register({
                required,
              })}
              onChange={(event): any => {
                dispatch(updateEmail(event.target.value));
              }}
              onKeyDown={(e: any): void => onKeyDown(e, () => buttonAction())}
            />
            <div />
          </div>
          <p className="desc">{about_you_description}</p>
          <div className="checkboxcontainer checkbox">
            <input
              type="checkbox"
              onClick={() => {
                setCheckBox1State(!checkBox1State);
              }}
            />
            <div className="checkbox-label">
              <PrismicRichText render={planDescription?.includes('MyDU') ? about_you_checkbox3 : about_you_checkbox1} />
            </div>
          </div>
          <div className="checkboxcontainer checkbox second">
            <input
              type="checkbox"
              onClick={() => {
                setCheckBox2State(!checkBox2State);
              }}
            />
            <div className="checkbox-label">
              <PrismicRichText render={about_you_checkbox2} />
            </div>
          </div>
          {contents && (
            <div className={'centerButton'}>
              <button
                disabled={loading || !checkBox1State || !checkBox2State}
                className={`theme-button-spec-positive continue`}
                data-xpaystation-widget-open
                onClick={buttonAction}
              >
                <div className="theme-button-container">
                  <div />
                  <span>{continueLabel}</span>
                </div>
              </button>
            </div>
          )}

          <p className="signin">{already_signed_in}</p>
        </div>
      </section>
      {planDescription?.includes('MyDU') && (
        <section className="buypage-aboutyou convert-gt">
          <div className="container">
            {/* {error?.errors && checkBox3State && displayErrorType1(error?.errors)} */}
            <div className="title">
              <PrismicRichText render={convert_gt_title} />
            </div>
            <div className="convert-desc">
              <PrismicRichText render={convert_gt_desc} />
            </div>
            <div className="checkboxcontainer checkbox">
              <input
                type="checkbox"
                onClick={() => {
                  setCheckBox3State(!checkBox3State);
                }}
              />
              <div className="checkbox-label">
                <PrismicRichText render={about_you_checkbox3} />
              </div>
            </div>
            <div className={'centerButton'}>
              <button
                disabled={!checkBox3State}
                className={`theme-button-spec-positive continue`}
                onClick={() => setConvertModal(true)}
              >
                <div className="theme-button-container">
                  <div />
                  <span>{continueLabel}</span>
                </div>
              </button>
            </div>
            <p className="signin">{already_signed_in}</p>
          </div>
        </section>
      )}
    </>
  );
};

export default BuyPageAboutYou;
