export interface Plan {
  productId: string;
  pricingId: string;
  type: string;
  paymentProviderId: string;
  templateId: string;
  currency: string;
  price: number;
  country: string;
  planname: string;
  plansubscriptionfreemonths: string;
  planDescription: string;

  campaignId: string;
  referallCode: string;
  planName: string;
}
export interface PlanState {
  loading: boolean;
  hasErrors: boolean;
  contents: Plan | null;
  error: any;
}

export const initialPlanState: PlanState = {
  contents: null,
  hasErrors: false,
  loading: false,
  error: false,
};
