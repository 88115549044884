import React, { useEffect, useState } from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';
import { useAuth } from '../../../stores/selectors/auth.selector';
import { usePlan } from '../../../stores/selectors/plan.selector';
import { ghostSignUp } from '../../../stores/actions/auth.action';
import { useAppDispatch } from '../../../stores/app.store.config';
import BuyPageEnterCode from './buypage-entercode';
import { makePurchase } from '../../../stores/actions/purchase.actions';
import { useCpmStar } from '../../../stores/selectors/cpmstar.selector';
import LinkComponent from '../../link/link.component';

const BuyPageActions = (): JSX.Element => {
  const {
    result: { cancel },
  } = usePrismic('common');

  const auth: any = useAuth();

  const dispatch = useAppDispatch();

  const [buttonActive, setButtonActive] = useState(false);

  const { contents } = usePlan();

  const { pricingId = '', campaignId = '', productId = '' } = contents || {};

  const { content: cpmContent } = useCpmStar();

  const buttonAction = async (): Promise<void> => {
    setButtonActive(true);

    if (!auth.user && Object.keys(cpmContent).length > 0) {
      dispatch(
        ghostSignUp({
          email: auth.email,
          siteid: cpmContent.siteid,
          ref: cpmContent.ref,
        }),
      );
    } else if (!auth.user) {
      dispatch(
        ghostSignUp({
          email: auth.email,
        }),
      );
    }

    if (auth.user) {
      dispatch(makePurchase({ pricingId, campaignId, productId }));
    }
  };

  useEffect(() => {
    if (auth.user && buttonActive) {
      buttonAction();
      setButtonActive(false);
    }
  }, [auth]);

  return contents ? (
    <div className="buypage-actions">
      <BuyPageEnterCode />
      <div className="btn-container">
        <div className="side">
          <LinkComponent className="cancel" link="/buy">
            {cancel}
          </LinkComponent>
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default BuyPageActions;
