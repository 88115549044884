import React, { useEffect, useState } from 'react';
import { displayDate } from '../../../services/utils/utils.service';
import { getSubscriptions } from '../../../stores/actions/subscriptions.actions';
import { useAppDispatch } from '../../../stores/app.store.config';
import { getAllSubscriptionsService, getOrdersService } from '../../../services/api/orders/order.subscriptions.service';
import { usePrismic } from '../../../hooks/prismic.hook';
import PrismicRichText from '../../../services/prismic/prismic.richtext.service';
import Media from 'react-media';

const AccountPayments = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const [orders, setOrders] = useState([]);
  const [subscriptions, setSubscriptions] = useState<any[]>([]);
  const [showPayments, setShowPayments] = useState('');
  const [indexSubs, setIndexSubs] = useState(-1);
  const [toggle, setToggle] = useState(true);
  const {
    data: {
      page_title,
      subs_column1,
      subs_column2,
      subs_column3,
      subs_column4,
      subs_column5,
      pay_column1,
      pay_column2,
      pay_column3,
      pay_column4,
    },
  } = usePrismic('account_payments');
  const {
    result: { promotion },
  } = usePrismic('buy_page');
  const paymentStatus = ['Started', 'Cancelled', 'Paid', 'Refunded', 'Rejected', 'Failed'];
  const subscriptionStatus = ['Active', 'Canceled'];

  useEffect(() => {
    dispatch(getSubscriptions());
  }, [dispatch]);
  useEffect(() => {
    if (showPayments) listOrders();
  }, [showPayments]);

  useEffect(() => {
    if (subscriptions?.length === 0) listSubscriptions();
  }, [subscriptions]);

  const listOrders = async () => {
    const orders = await getOrdersService(showPayments);
    setOrders(orders);
    return orders;
  };

  const listSubscriptions = async () => {
    const subscriptions = await getAllSubscriptionsService();
    setSubscriptions(subscriptions);
    return subscriptions;
  };

  return (
    <div className="account-payments-container">
      <div className="account-payments-title">
        <PrismicRichText render={page_title} />
      </div>
      <Media
        queries={{
          small: '(max-width: 479px)',
          large: '(min-width: 480px)',
        }}
      >
        {(matches): JSX.Element => (
          <>
            {matches.large && (
              <>
                <div className="account-payments-history">
                  <div className="table">
                    <div className="row">
                      <div className="column yellow">
                        <PrismicRichText render={subs_column1} />
                      </div>

                      <div className="column yellow">
                        <PrismicRichText render={subs_column2} />
                      </div>

                      <div className="column yellow">
                        <PrismicRichText render={subs_column3} />
                      </div>

                      <div className="column yellow">
                        <PrismicRichText render={subs_column4} />
                      </div>
                      <div className="column yellow">
                        <PrismicRichText render={subs_column5} />
                      </div>
                      <div className="column yellow"></div>
                    </div>
                    {(subscriptions || [])?.map((item: any, index: number) => {
                      return (
                        <div key={index}>
                          {index <= indexSubs && (
                            <div
                              className={index !== indexSubs || !showPayments ? 'row' : 'row selected'}
                              onClick={() => {
                                if (index === indexSubs) {
                                  if (toggle) setShowPayments('');
                                  else {
                                    setShowPayments(item.id);
                                  }
                                  setToggle(!toggle);
                                } else {
                                  setToggle(true);
                                  setIndexSubs(index);
                                  setShowPayments(item.id); //assign to payments the subscription Id
                                }
                              }}
                            >
                              {<div className="column">{displayDate(item.dateCreate)}</div>}
                              {item?.status === 0 && <div className="column">{displayDate(item?.dateNextCharge)}</div>}
                              {item?.status === 1 && <div className="column"></div>}
                              {item?.status === 1 && <div className="column">{displayDate(item?.dateEnd)}</div>}
                              {item?.status === 0 && <div className="column"></div>}
                              <div className="column">{item?.paymentProvider === 0 ? 'Xsolla' : 'Steam'}</div>
                              <div className={item?.status === 0 ? 'column active' : 'column'}>
                                {subscriptionStatus[item?.status]}
                              </div>
                              <div className="column">
                                {index !== indexSubs && toggle && <div className="menuIcon"></div>}
                                {index === indexSubs && toggle && <div className="menuIcon menuIconClose"></div>}
                                {index === indexSubs && !toggle && <div className="menuIcon"></div>}
                                {index !== indexSubs && !toggle && <div className="menuIcon"></div>}
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                  {showPayments && (
                    <div className="table payments">
                      <h1 className="account-payments-subtitle">Payments</h1>
                      <div className="row payments">
                        <div className="column yellow">
                          <PrismicRichText render={pay_column1} />
                        </div>
                        <div className="column yellow">
                          <PrismicRichText render={pay_column2} />
                        </div>
                        <div className="column yellow">
                          <PrismicRichText render={pay_column3} />
                        </div>
                        <div className="column yellow">
                          <PrismicRichText render={pay_column4} />
                        </div>
                      </div>
                      {(orders || []).map((item: any, index: number) => {
                        return (
                          <div className="row payments" key={index}>
                            {item?.paidAt && <div className="column">{displayDate(item.paidAt)}</div>}
                            {Number(promotion) != 0 &&
                              new Date(subscriptions[indexSubs]?.dateCreate) >= new Date(2023, 9, 12) &&
                              new Date(subscriptions[indexSubs]?.dateCreate) <= new Date(2023, 9, 26) && (
                                <div className="column">
                                  {Math.round((item?.price - item?.price * Number(promotion)) * 100) / 100}
                                </div>
                              )}
                            {Number(promotion) == 0 && <div className="column">{item?.price}</div>}
                            <div className="column">{item?.currency}</div>
                            <div className="column">{paymentStatus[item?.status]}</div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {(subscriptions || [])?.map((item: any, index: number) => {
                    return (
                      <div key={index}>
                        {index > indexSubs && (
                          <div
                            className={index !== indexSubs || !showPayments ? 'row' : 'row selected'}
                            onClick={() => {
                              if (index === indexSubs) {
                                if (toggle) setShowPayments('');
                                else {
                                  setShowPayments(item.id);
                                }
                                setToggle(!toggle);
                              } else {
                                setToggle(true);
                                setIndexSubs(index);
                                setShowPayments(item.id); //assign to payments the subscription Id
                              }
                            }}
                          >
                            {<div className="column">{displayDate(item.dateCreate)}</div>}
                            {item?.status === 0 && <div className="column">{displayDate(item?.dateNextCharge)}</div>}
                            {item?.status === 1 && <div className="column"></div>}
                            {item?.status === 1 && <div className="column">{displayDate(item?.dateEnd)}</div>}
                            {item?.status === 0 && <div className="column"></div>}
                            <div className="column">{item?.paymentProvider === 0 ? 'Xsolla' : 'Steam'}</div>
                            <div className={item?.status === 0 ? 'column active' : 'column'}>
                              {subscriptionStatus[item?.status]}
                            </div>
                            <div className="column">
                              {index !== indexSubs && toggle && <div className="menuIcon"></div>}
                              {index === indexSubs && toggle && <div className="menuIcon menuIconClose"></div>}
                              {index === indexSubs && !toggle && <div className="menuIcon"></div>}
                              {index !== indexSubs && !toggle && <div className="menuIcon"></div>}
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            {matches.small && (
              <div className="account-table-mobile">
                {(subscriptions || [])?.map((item: any, index: number) => {
                  return (
                    <div key={index} id={`${index}`}>
                      {index <= indexSubs && (
                        <div className="corners">
                          <div className="box">
                            <div className="column-left">
                              <div className="item yellow">
                                <PrismicRichText render={subs_column1} />
                              </div>

                              <div className="item yellow">
                                <PrismicRichText render={subs_column2} />
                              </div>

                              <div className="item yellow">
                                <PrismicRichText render={subs_column3} />
                              </div>

                              <div className="item yellow">
                                <PrismicRichText render={subs_column4} />
                              </div>
                              <div className="item yellow">
                                <PrismicRichText render={subs_column5} />
                              </div>
                              <div className="item yellow"></div>
                            </div>
                            <div className="column-right">
                              {<div className="item">{displayDate(item.dateCreate)}</div>}
                              {item?.status === 0 && <div className="item">{displayDate(item?.dateNextCharge)}</div>}
                              {item?.status === 1 && <div className="item void">Empty</div>}
                              {item?.status === 1 && <div className="item">{displayDate(item?.dateEnd)}</div>}
                              {item?.status === 0 && <div className="item void">Empty</div>}
                              <div className="item">{item?.paymentProvider === 0 ? 'Xsolla' : 'Steam'}</div>
                              <div className={item?.status === 0 ? 'item active' : 'item'}>
                                {subscriptionStatus[item?.status]}
                              </div>
                            </div>
                            <a className="pay-btn-link" href={`#${index}`}>
                              <button
                                className="pay-btn"
                                onClick={() => {
                                  if (index === indexSubs) {
                                    if (toggle) setShowPayments('');
                                    else {
                                      setShowPayments(item.id);
                                    }
                                    setToggle(!toggle);
                                  } else {
                                    setToggle(true);
                                    setIndexSubs(index);
                                    setShowPayments(item.id); //assign to payments the subscription Id
                                  }
                                }}
                              >
                                <div />
                                <span>See Payments</span>
                              </button>
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
                {showPayments && (
                  <div className="payments-mobile-container">
                    <h1 className="account-payments-subtitle">Payments</h1>
                    <div className="table payments">
                      <div className="column-left">
                        <div className="item yellow">
                          <PrismicRichText render={pay_column1} />
                        </div>
                        <div className="item yellow">
                          <PrismicRichText render={pay_column2} />
                        </div>
                        <div className="item yellow">
                          <PrismicRichText render={pay_column3} />
                        </div>
                        <div className="item yellow">
                          <PrismicRichText render={pay_column4} />
                        </div>
                      </div>
                      {(orders || []).map((item: any, index: number) => {
                        return (
                          <div className="column-right" key={index}>
                            {item?.paidAt && <div className="item">{displayDate(item.paidAt)}</div>}
                            <div className="item">{item?.price}</div>
                            <div className="item">{item?.currency}</div>
                            <div className="item">{paymentStatus[item?.status]}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {(subscriptions || [])?.map((item: any, index: number) => {
                  return (
                    <div key={index} id={`${index}`}>
                      {index > indexSubs && (
                        <div className="corners">
                          <div className="box">
                            <div className="column-left">
                              <div className="item yellow">
                                <PrismicRichText render={subs_column1} />
                              </div>

                              <div className="item yellow">
                                <PrismicRichText render={subs_column2} />
                              </div>

                              <div className="item yellow">
                                <PrismicRichText render={subs_column3} />
                              </div>

                              <div className="item yellow">
                                <PrismicRichText render={subs_column4} />
                              </div>
                              <div className="item yellow">
                                <PrismicRichText render={subs_column5} />
                              </div>
                              <div className="item yellow"></div>
                            </div>
                            <div className="column-right">
                              {<div className="item">{displayDate(item.dateCreate)}</div>}
                              {item?.status === 0 && <div className="item">{displayDate(item?.dateNextCharge)}</div>}
                              {item?.status === 1 && <div className="item void">Empty</div>}
                              {item?.status === 1 && <div className="item">{displayDate(item?.dateEnd)}</div>}
                              {item?.status === 0 && <div className="item void">Empty</div>}
                              <div className="item">{item?.paymentProvider === 0 ? 'Xsolla' : 'Steam'}</div>
                              <div className={item?.status === 0 ? 'item active' : 'item'}>
                                {subscriptionStatus[item?.status]}
                              </div>
                            </div>
                            <a className="pay-btn-link" href={`#${index}`}>
                              <button
                                className="pay-btn"
                                onClick={() => {
                                  if (index === indexSubs) {
                                    if (toggle) setShowPayments('');
                                    else {
                                      setShowPayments(item.id);
                                    }
                                    setToggle(!toggle);
                                  } else {
                                    setToggle(true);
                                    setIndexSubs(index);
                                    setShowPayments(item.id); //assign to payments the subscription Id
                                  }
                                }}
                              >
                                <div />
                                <span>See Payments</span>
                              </button>
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
      </Media>
    </div>
  );
};

export default AccountPayments;
